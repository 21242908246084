<template>
    <div class="OKRManagement">
        <div class="left">
            <div class="leftSeach" style="display: none;">
                <person v-if="showsearch" class="person" v-bind="personObj3" @getData="participatePerson2"></person>
            </div>
            <div class="treeSelect">
                <a-menu mode="inline" :open-keys="openKeys" style="width: 100%" @openChange="onOpenChange"
                        @click="handleClick">
                    <a-sub-menu key="sub1">
                        <span slot="title" @click="_allData"><a-icon type="caret-down"/><span>我相关的</span></span>
                        <a-menu-item key="1">我负责的</a-menu-item>
                        <a-menu-item key="2">我参与的</a-menu-item>
                        <a-menu-item key="3">我关注的</a-menu-item>
                        <a-menu-item key="4">我创建的</a-menu-item>
                        <a-menu-item key="5">已过期的</a-menu-item>
                    </a-sub-menu>
                </a-menu>
                <a-tree v-if="treeData" :treeData="treeData" :defaultExpandedKeys="['9999']" @select="onSelect"></a-tree>
            </div>
        </div>
        <div class="right">
            <div class="projectHead">
                <div class="avatarName" v-if="userInfo">
                    <!-- <img class="img" :src="item.Avatar" alt="" /> -->
                    <img class="img" :src="userInfo.Avatar" alt=""/>
                    <span>{{ userInfo.name }}</span>
                </div>
                <div class="create">
                    <a-switch checked-children="显示已过期" un-checked-children="显示已过期" style="width:100px;margin-right:20px" @change="onSwitch" />
                    <a-button id="button" type="primary" @click="_open" :value='nameValue'>创建</a-button>
                </div>
            </div>
            <div class="projectContent" >
                <div class="item" v-if="DataList.length > 0">
                    <div class="itemTop">{{ Title }}</div>
                    <div
                            class="itemBottom"
                            v-if="DataList.length > 0"
                            v-for="(item, index) in DataList"
                            :key="index"
                            @click.stop="oneProject(item)"
                            :style="item.isExpire ==1?'color:#b5b6c7':'' "
                    >
                        <div class="itemBottom1">
                            <img
                                    class="img"
                                    style="width: 45px; height: 45px; border-radius: 4px"
                                    :src="item.Avatar"
                                    alt=""
                            />
                            <div class="titleContent">
                                <span class="itemTitle"><span class="tags" v-if="item.TypeStr">{{item.TypeStr}}</span>{{ item.Name }}</span>
                                <span class="itemContent">{{ item.SubName }}</span>
                            </div>
                        </div>
                        <div class="itemBottom2" v-if="item.ByDate">
                          <div class="time" :style="item.isExpire ==1?'color:#b5b6c7':'' ">{{ item.ByDate }}</div>
                          <span class="endTime">起止时间</span>
                        </div>
                        <!-- <div class="itemBottom3">
                          <span class="role">{{ item.CategoryName }}</span>
                        </div> -->
                        <!-- <div class="itemBottom4">
                          <div class="digital">
                            <span class="barNumber">{{ item.ProjectSpeed }}%</span>
                            <span class="barProgress">进度</span>
                          </div>
                          <div class="progress">
                            <a-progress :percent="item.ProjectSpeed" :show-info="false" />
                          </div>
                        </div> -->
                        <div class="itemBottom5">
                            <div class="editor" :style="item.Type!=0?'pointer-events: none;':''">
                                <a-popover placement="bottom">
                                    <template slot="content" >
                                      <div class="edit-item">
                                          <a href="#" @click.stop="_copy(item.Id)">复制</a>
                                      </div>
                                      <div class="edit-item">
                                          <a href="#" @click.stop="toEditor(item.Id)">编辑</a>
                                      </div>
                                      <div class="edit-item">
                                        <a-popconfirm
                                            title="你确定要删除吗?"
                                            ok-text="确定"
                                            cancel-text="取消"
                                            @confirm="toDelete(item.Id, index, 'Responsible')">
                                            <a href="#" @click.stop>删除</a>
                                        </a-popconfirm>
                                      </div>
                                    </template>
                                    <!-- <a-icon type="small-dash" class="smalldash"/> -->
                                    <a-icon type="dash" class="smalldash" @click.stop/>
                                </a-popover>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="pagination" v-if="DataCount > 10" style="margin-top:30px;">
                        <a-pagination v-model="CurrentPage" :total="DataCount" show-less-items  @change="onChangePage"/>
                    </div> -->

                    <div class="more" v-if="more_data">
                        <a-button class="more-a" @click="_load_more">加载更多</a-button>
                    </div>
                </div>

                
                

                <!-- 我参与的 -->
                <div
                        class="item"
                        v-if="(menuKey == 2 || menuKey == '') && AboutMeData.Participate && AboutMeData.Participate.List.length > 0
          "
                >
                    <div class="itemTop">{{ AboutMeData.Participate.Title }}</div>
                    <div
                            class="itemBottom"
                            v-if="AboutMeData.Participate.List.length > 0"
                            v-for="(item, index) in AboutMeData.Participate.List"
                            :key="index"
                            @click.stop="oneProject(item)"
                            :style="item.isExpire ==1?'color:#b5b6c7':'' "
                    >
                        <div class="itemBottom1">
                            <img
                                    class="img"
                                    style="width: 45px; height: 45px; border-radius: 4px"
                                    :src="item.Avatar"
                                    alt=""
                            />
                            <div class="titleContent">
                                <span class="itemTitle">{{ item.Name }}</span>
                                <span class="itemContent">{{ item.SubName }}</span>
                            </div>
                        </div>
                        <div class="itemBottom2" v-if="this.DataCount == 10">
                          <div class="time" :style="item.isExpire ==1?'color:#b5b6c7':'' ">{{ item.ByDate }}</div>
                          <span class="endTime">起止时间</span>
                        </div>
                        <!-- <div class="itemBottom3">
                          <span class="role">{{ item.CategoryName }}</span>
                        </div>
                        <div class="itemBottom4">
                          <div class="digital">
                            <span class="barNumber">{{ item.ProjectSpeed }}%</span>
                            <span class="barProgress">进度</span>
                          </div>
                          <div class="progress">
                            <a-progress :percent="item.ProjectSpeed" :show-info="false" />
                          </div>
                        </div> -->
                        <div class="itemBottom5">
                            <div class="editor">
                                <a-popover placement="bottom">
                                    <template slot="content" >
                                      <div class="edit-item">
                                          <a href="#" @click.stop="_copy(item.Id)">复制</a>
                                      </div>
                                      <div class="edit-item">
                                          <a href="#" @click.stop="toEditor(item.Id)">编辑</a>
                                      </div>
                                      <div class="edit-item">
                                        <a-popconfirm
                                            title="你确定要删除吗?"
                                            ok-text="确定"
                                            cancel-text="取消"
                                            @confirm="toDelete(item.Id, index, 'Participate')">
                                            <a href="#" @click.stop>删除</a>
                                        </a-popconfirm>
                                      </div>
                                    </template>
                                    <a-icon type="small-dash" class="smalldash"/>
                                </a-popover>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 我创建的 -->
                <div
                        class="item"
                        v-if="
            (menuKey == 4 || menuKey == '') &&
            AboutMeData.Create &&
            AboutMeData.Create.List.length > 0
          "
                >
                    <div class="itemTop">{{ AboutMeData.Create.Title }}</div>
                    <div
                            class="itemBottom"
                            v-if="AboutMeData.Create.List.length > 0"
                            v-for="(item, index) in AboutMeData.Create.List"
                            :key="index"
                            @click="oneProject(item)"
                            :style="item.isExpire ==1?'color:#b5b6c7':'' "
                    >
                        <!--@click="oneProject(item.Id)"-->
                        <div class="itemBottom1">
                            <img
                                    class="img"
                                    style="width: 45px; height: 45px; border-radius: 4px"
                                    :src="item.Avatar"
                                    alt=""
                            />
                            <div class="titleContent">
                                <span class="itemTitle">{{ item.Name }}</span>
                                <span class="itemContent">{{ item.SubName }}</span>
                            </div>
                        </div>
                        <div class="itemBottom2" v-if="item.ByDate">
                          <div class="time" :style="item.isExpire ==1?'color:#b5b6c7':'' ">{{ item.ByDate }}</div>
                          <span class="endTime">起止时间</span>
                        </div>
                        <!-- <div class="itemBottom3">
                          <span class="role">{{ item.CategoryName }}</span>
                        </div>
                        <div class="itemBottom4">
                          <div class="digital">
                            <span class="barNumber">{{ item.ProjectSpeed }}%</span>
                            <span class="barProgress">进度</span>
                          </div>
                          <div class="progress">
                            <a-progress :percent="item.ProjectSpeed" :show-info="false" />
                          </div>
                        </div> -->
                        <div class="itemBottom5">
                            <div class="editor">
                                <a-popover placement="bottom">
                                    <template slot="content" >
                                      <div class="edit-item">
                                          <a href="#" @click.stop="_copy(item.Id)">复制</a>
                                      </div>
                                      <div class="edit-item">
                                          <a href="#" @click.stop="toEditor(item.Id)">编辑</a>
                                      </div>
                                      <div class="edit-item">
                                        <a-popconfirm
                                            title="你确定要删除吗?"
                                            ok-text="确定"
                                            cancel-text="取消"
                                            @confirm="toDelete(item.Id, index, 'Create', $event)">
                                            <a href="#" @click.stop>删除</a>
                                        </a-popconfirm>
                                      </div>
                                    </template>
                                    <a-icon type="small-dash" class="smalldash"/>
                                </a-popover>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 关注  -->
                <div
                        class="item"
                        v-if="
            (menuKey == 3 || menuKey == '') &&
            AboutMeData.Follow &&
            AboutMeData.Follow.List.length > 0
          "
                >
                    <div class="itemTop">{{ AboutMeData.Follow.Title }}</div>
                    <div
                            class="itemBottom"
                            v-if="AboutMeData.Follow.List.length > 0"
                            v-for="(item, index) in AboutMeData.Follow.List"
                            :key="index"
                            @click.stop="oneProject(item)"
                            :style="item.isExpire ==1?'color:#b5b6c7':'' "
                    >
                        <div class="itemBottom1">
                            <img class="img" style="width: 45px; height: 45px;border-radius: 4px;" :src="item.Avatar" alt=""/>
                            <div class="titleContent">
                                <span class="itemTitle">{{ item.Name }}</span>
                                <span class="itemContent">{{ item.SubName }}</span>
                            </div>
                        </div>
                        <div class="itemBottom2" v-if="item.ByDate">
                          <div class="time" :style="item.isExpire ==1?'color:#b5b6c7':'' ">{{ item.ByDate }}</div>
                          <span class="endTime">起止时间</span>
                        </div>
                        <!-- <div class="itemBottom3">
                          <span class="role">{{ item.CategoryName }}</span>
                        </div> -->
                        <!-- <div class="itemBottom4">
                          <div class="digital">
                            <span class="barNumber">{{ item.ProjectSpeed }}%</span>
                            <span class="barProgress">进度</span>
                          </div>
                          <div class="progress">
                            <a-progress :percent="item.ProjectSpeed" :show-info="false" />
                          </div>
                        </div> -->
                        <div class="itemBottom5">
                            <div class="editor">
                                <a-popover placement="bottom">
                                    <template slot="content" >
                                      <div class="edit-item">
                                          <a href="#" @click.stop="_copy(item.Id)">复制</a>
                                      </div>
                                      <div class="edit-item">
                                          <a href="#" @click.stop="toEditor(item.Id)">编辑</a>
                                      </div>
                                      <div class="edit-item">
                                        <a-popconfirm
                                            title="你确定要删除吗?"
                                            ok-text="确定"
                                            cancel-text="取消"
                                            @confirm.stop="toDelete(item.Id, index, 'Follow')">
                                            <a href="#" @click.stop>删除</a>
                                        </a-popconfirm>
                                      </div>
                                    </template>
                                    <a-icon type="small-dash" class="smalldash"/>
                                </a-popover>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--修改添加-->
        <a-drawer :title="buttonValue" width="600" placement="right" :closable="false" @close="_cancelData()" :bodyStyle="{padding: 0}" :visible="detailVisible">
            <div class="OKRDetails" style="padding: 20px 20px 50px;">
                <div class="content">
                    <input
                            class="int"
                            type="text"
                            placeholder="请添加标题"
                            v-model="alertTitle"
                    />
                    <div class="starStop">
                        <div class="lintTime">起止时间</div>
                        <div class="lintDate">
                            <a-range-picker v-model="startDate" @change="_customDate"/>
                        </div>
                    </div>
                    <div class="projectPersonnel">
                        <div class="responsiblePerson">
                            <div class="nickName">主要负责人(负责人只能添加一个)</div>
                            <div class="headPortrait">
                                <div class="itemPortrait" v-if="readPerson">
                                    <img
                                            class="img"
                                            :src="readPerson[0].icon || readPerson[0].Avatar"
                                            alt=""
                                    />
                                    <div>{{ readPerson[0].title || readPerson[0].UserName }}</div>
                                </div>
                                <div class="itemPortrait">
                                    <i
                                            class="iconfont"
                                            style="position: relative; top: -10px; font-size: 37px"
                                    >&#xe676;</i
                                    >
                                    <div></div>
                                    <person
                                            class="person"
                                            v-bind="personObj"
                                            @getData="readChangePerson"
                                    ></person>
                                </div>
                            </div>
                        </div>
                        <div class="responsiblePerson" style="margin-top: 15px">
                            <div class="nickName">参与人员</div>
                            <div class="headPortrait">
                                <div class="itemPortrait" v-if="participate" v-for="(item, index) in participate" :key="index">
                                    <img class="img" :src="item.icon || item.Avatar" alt=""/>
                                    <div>{{ item.title || item.UserName }}</div>
                                    <i class="iconfont icon-bohui del-icon" @click="_delPerson(index)"></i>
                                </div>
                                <div class="itemPortrait">
                                    <i class="iconfont" style="position: relative; top: -10px; font-size: 37px">&#xe676;</i>
                                    <div></div>
                                    <person class="person" style="overflow: hidden" v-bind="personObj2" @getData="participatePerson"></person>
                                </div>
                            </div>
                        </div>
                        <!--对齐项目-->
                        <!-- <div class="center">
                            <div class="center-title">对齐项目</div>
                            <div class="center-list">
                                <div class="list-item" v-for="item in OBindData">
                                    <div>
                                        <img class="item-avatar" :src="item.Avatar" alt=""/>
                                        <span class="item-name">{{item.CreateUserName}}</span>
                                    </div>
                                    <div class="item-txt">{{item.Name}}</div>
                                    <i class="iconfont tab-del" @click="_delProject">&#xe60a;</i>
                                </div>
                            </div>
                            <a-button class="add-obj" style="height: 36px;width: 250px;margin-top: 5px;" @click="_showProjectModel"><a-icon type="plus"/>关联项目</a-button>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_cancelData()">取消</a-button>
                <a-button type="primary" @click="_save()">确定</a-button>
            </div>
        </a-drawer>
        <!--关联项目-->
        <a-drawer title="对齐项目" width="500" placement="right" :closable="false" @close="_relevanceCencel" :visible="relevanceVisible">
            <div class="relevanceContent">
                <div>
                    <a-input-search v-model="searchTackText" style="width: 300px" placeholder="搜索员工" @keyup.enter="_bingTask()" enter-button @search="_bingTask()"></a-input-search>
                </div>

                <div class="center-list">
                    <div class="list-item" style="cursor: pointer; margin-top: 15px" v-for="(item, index) in relevanceList" @click="_addRelevance(index)">
                        <div>
                            <img class="item-avatar" :src="item.Avatar" alt=""/>
                            <span class="item-name">{{ item.DescriptionName }}</span>
                        </div>
                        <div class="item-txt">{{ item.Name }}</div>
                        <i v-if="relevanceSelect.indexOf(index) == -1" class="iconfont icon-yuanxing icon-radio"></i>
                        <i v-else class="iconfont icon-tongyi icon-radio" style="color: #1890ff"></i>
                        <div class="schedule"></div>
                    </div>
                </div>
            </div>

            <div class="alert-footer">
                <a-button style="margin-right: 25px" @click="_relevanceCencel">取消</a-button>
                <a-button type="primary" @click="_saveRelevance">确定</a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import person from "@/components/SelectPersonnel/SelectPersonnel";
    import {Button} from 'ant-design-vue';

    export default {
        components: {
            person,
        },
        data() {
            return {
                number: 100,
				showsearch:false,
                value: undefined,
                rootSubmenuKeys: ["sub1", "sub2", "sub3"],
                openKeys: ["sub1"],
                treeData: "",
                visible: false,
                confirmLoading: false,
                ModalText: "hello",
                detailVisible: false,
                nameValue: '',
                userName: "",
                AboutMeData: "",
                userInfo: JSON.parse(window.sessionStorage.getItem("userInfo")),
                selectDate: "",
                personObj: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                personObj2: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 10000, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                },
                personObj3: {
                    rank: 1, //职级 0为不选，1为选
                    nums: 1, //选的数量
                    department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                    contacts: "", //常用联系人 不要常用联系人传空''
                    selectArr: [], //要传入的数据
                    placeholder: "搜索名称"
                },
                setId: "",
                alertTitle: "",
                createPer: "",
                startDate: [],
                readPerson: "",
                participate: "",
                menuKey: "",
                selectTree: "",
                selectOrgName:"",
                OrgDataList: '',
                buttonValue: '',
                advice: "创建",
                relevanceVisible: false,
                relevanceList: [],
                relevanceSelect: [],
                searchTackText: '',
                OBindData: [],
                isShowExpire:0,
                Title:'',
                DataList:[],
                CurrentPage:1,
                DataCount:0,
                more_data:true,
            };
        },
        created() {
            this.createPer = this.userInfo;
            this.$axios.get(8002, {Pid: 0}, (res) => {
                if (res.data.list) {
                    //this.treeData = res.data.list; //20230430 不显示组织列表ZHONG
                }
            });
            this._allData();
        },

        methods: {
            _delProject(){
                this.OBindData = [];
            },
            _saveRelevance() {
                this.relevanceSelect.map((res) => {
                    // this.OBindData.push(this.relevanceList[res]);
                    this.OBindData = [this.relevanceList[res]];
                });
                this._relevanceCencel();
            },
            _bingTask(){
                this._projectList();
            },
            _addRelevance(i) {
                if (this.relevanceSelect.indexOf(i) == -1) {
                    this.$set(this.relevanceSelect, 0, i);
                } else {
                    this.relevanceSelect.splice(this.relevanceSelect.indexOf(i), 1);
                }
            },
            _relevanceCencel(){
                this.relevanceVisible = false;
                this.searchTackText = "";
                this.relevanceList = [];
                this.relevanceSelect = [];
            },
            _showProjectModel(){
                this._projectList();
            },
            _projectList(){
                this.$axios.get(8000079, {Name:this.searchTackText}, (res) => {
                    this.relevanceList = res.data.data;
                    this.relevanceVisible = true;
                })
            },
            _open() {
                this.detailVisible = true;
                this.buttonValue = document.getElementById('button').innerText;
                if (this.buttonValue) {
                    this.buttonValue = '创建'
                }
            },
            onSelect(selectedKeys, info) {
                this.DataList = [];
                this.DataCount = 0;
                this.CurrentPage = 1;
                this.menuKey = "";
                this.selectOrgName = info.node.dataRef.title
                this.selectTree = selectedKeys;
                this._infoData();
            },
            _allData() {
                this.menuKey = "";
                this.selectTree = "";
                this.CurrentPage = 1;
                this.DataList = [];
                this.DataCount = 0;
                this._infoData();
            },
            _save() {
                let OBindData = [];
                this.OBindData.map((tab) => {
                    OBindData.push(tab.Id);
                });

                let item = {
                    Name: this.alertTitle,
                    StartDate: this.selectDate[0],
                    EndDate: this.selectDate[1],
                    OperationType: 0,
                    Id: this.setId,
                    Directors: JSON.stringify(this.readPerson),
                    // Directors: this.readPerson,
                    Participants: JSON.stringify(this.participate),
                    ToOkrIdList: OBindData.length > 0 ? JSON.stringify(OBindData) : "",
                };
                this.$axios.post(8000043, item, (res) => {
                    if (res.data.code == 1) {
                        this.DataList = [];
                        this.CurrentPage = 1;
                        this.DataCount=0;
                        this.menuKey = "";
                        this.selectTree = "";
                        this.selectOrgName = "";
                        if (!this.setId) {
                            this._infoData();
                            // this.AboutMeData.Create.unshift(res.data.data)
                        } else {
                            this._infoData();
                        }
                        this._cancelData();
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            },
            toEditor(id) {
                this.buttonValue = '编辑'
                this.setId = id;
                this.$axios.get(8000041, {ProjectId: id}, (res) => {
                    if (res.data.code == 1) {
                        console.log(res.data.data);
                        let data = res.data.data;
                        this.alertTitle = data.Info.Name;
                        this.createPer = {name: data.Info.CreateUserName};
                        this.selectDate = [data.Info.StartDate, data.Info.PlanEndDate];
                        if (data.Info.StartDate && data.Info.PlanEndDate) {
                            this.startDate = [
                                this.__moment__(data.Info.StartDate),
                                this.__moment__(data.Info.PlanEndDate),
                            ];
                        }
                        this.readPerson = data.Responsible.length > 0 || data.Responsible ? [data.Responsible] : "";
                        this.personObj.selectArr = this.readPerson;
                        this.participate = data.Participate;
                        this.personObj2.selectArr = this.participate;
                        this.detailVisible = true;
                        this.OBindData = data.OBindData;
                    }
                });
            },
            _cancelData() {
                this.alertTitle = "";
                this.createPer = this.userInfo;
                this.startDate = [];
                this.selectDate = "";
                this.readPerson = "";
                this.participate = "";
                this.setId = "";
                this.detailVisible = false;
                this.personObj.selectArr = [];
                this.personObj2.selectArr = [];
                this.OBindData = [];
            },
            _infoData() {
                console.log(this.selectTree)
                this.$message.loading("加载中...");
                this.$axios.get(
                    8000106,
                    {AboutMeType: this.menuKey, OrgId: this.selectTree[0], OrgData: JSON.stringify(this.OrgDataList),isShowExpire:this.isShowExpire,Page:this.CurrentPage,OrgName:this.selectOrgName},
                    (res) => {
                        if (res.data.code == 1) {
                            this.Title = res.data.data.Title;
                            // this.DataList = res.data.data.List;
                            this.DataCount += 10;
                            if (res.data.data.count - this.DataCount <= 0) {
                                this.more_data = false;
                            } else {
                                this.more_data = true;
                            }
                            this.DataList = this.DataList.concat(res.data.data.List)
                            
                        } else {
                            // this.Title = '';
                            // this.DataList = '';
                            this.DataCount = 0;
                            // this.CurrentPage = 1;
                            // this.$message.error(res.data.message);
                            this.more_data = false;
                        }
                        this.$message.destroy();
                    }
                );
            },
            _delPerson(i) {
                this.participate.splice(i, 1);
            },
            participatePerson(value) {
                this.participate = value;
            },
            participatePerson2(value) {
                this.DataList = [];
                this.CurrentPage = 1;
                this.DataCount = 0;
                this.selectTree = '';
                this.selectOrgName = "";
                this.OrgDataList = value;
                this._infoData();
            },
            readChangePerson(value) {
                this.readPerson = value;
            },
            _customDate(date) {
                if (date.length > 0) {
                    this.selectDate = [
                        this.__moment__(date[0]).format("YYYY-MM-DD"),
                        this.__moment__(date[1]).format("YYYY-MM-DD"),
                    ];
                } else {
                    this.selectDate = ['',''];
                }
            },
            onOpenChange(openKeys) {
                const latestOpenKey = openKeys.find(
                    (key) => this.openKeys.indexOf(key) === -1
                );
                if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                    this.openKeys = openKeys;
                } else {
                    this.openKeys = latestOpenKey ? [latestOpenKey] : [];
                }
            },
            handleClick(e) {
                this.menuKey = e.key;
                this.selectTree = "";
                this.DataList = [];
                this.CurrentPage = 1;
                this.DataCount = 0;
                this._infoData();
            },
            toDelete(id, index, text, e) {
                this.$message.loading("加载中...");
                this.$axios.post(8000042, {Id: id}, (res) => {
                    if (res.data.code == 1) {
                        this.$message.success(res.data.message);
                        this.DataList.splice(index, 1);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.$message.destroy();
                });
            },
            oneProject(item) {
                let id,title,tabName;
                if (item.Type == 0) {
                    id = item.Id;
                    title = item.Name;
                    tabName = item.DescriptionName;
                } else {
                    id = item.ProjectId;
                    title = item.ProjectName;
                    tabName = item.CreateUserName;
                }
                this.$router.push({
                    path: "/OKRProjectDetails",
                    query: {id: id, title: title,tabName: tabName},
                });
            },
            onSwitch(checked,e){
                if (checked) {
                    this.isShowExpire = 1;
                } else {
                    this.isShowExpire = 0;
                }
                this.CurrentPage = 1;
                this.DataList = [];
                this.DataCount = 0;
                this._infoData();
            },
            _copy(ProjectId){
                this.$message.loading("加载中...");
                this.$axios.post(
                    8000103,
                    {ProjectId: ProjectId},
                    (res) => {
                        if (res.data.code == 1) {
                            this.menuKey = "";
                            this.selectTree = "";
                            this.DataList = [];
                            this.CurrentPage = 1;
                            this.DataCount = 0;
                            this.$message.success(res.data.message);
                            this._infoData();
                        } else {
                            this.$message.error(res.data.message);
                        }
                        this.$message.destroy();
                    }
                );
            },
            onChangePage(page, pageSize){
                console.log(page)
                this.CurrentPage = page;
                this._infoData();
            },
            _load_more(){
                this.CurrentPage++;
                this._infoData();
            }
        },
    };
</script>

<style lang="less">
    .expire_color{
        color: #b5b6c7;
    }
    .OKRManagement {
        display: flex;
        height: 100%;
        .left {
            width: 240px;
            height: 100%;
            background: #ffffff;
            opacity: 1;
            border-radius: 5px;
            overflow-y: scroll;
            .leftSeach {
                text-align: center;
                background-color: #fff;
                padding: 20px 16px;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                .input {
                    border: 1px solid #dddfe1;
                    opacity: 1;
                    border-radius: 2px;
                    padding: 6px 0 6px 36px;
                }
            }
            .treeSelect {
                padding: 0 16px;
            }
        }
        .right {
            padding-left: 19px;
            flex: 1;
            .projectHead {
                display: flex;
                justify-content: space-between;
                .avatarName {
                    .img {
                        width: 34px;
                        height: 35px;
                        opacity: 1;
                        border-radius: 23px;
                    }
                    span {
                        padding-left: 11px;
                        font-size: 16px;
                        //   font-family: .PingFang SC;
                        font-weight: 400;
                        line-height: 22px;
                        color: #3f4254;
                        opacity: 1;
                    }
                }
                .create {
                    color: #fff;
                    .btn {
                        padding: 6px 25px;
                        border: none;
                        background: #3974ff;
                        opacity: 1;
                    }
                }
            }
            .projectContent {
                padding-top: 13px;
                .item {
                    margin-bottom: 10px;
                    padding: 15px 11px 20px 20px;
                    border-radius: 6px;
                    background-color: #fff;
                    .itemTop {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #3f4254;
                        opacity: 1;
                    }
                    .itemBottom {
                        display: flex;
                        justify-content: space-between;
                        text-align: center;
                        padding-top: 15px;
                        cursor: pointer;
                        .itemBottom1 {
                            display: flex;
                            .titleContent {
                                margin-top: 5px;
                                display: flex;
                                padding-left: 19px;
                                flex-direction: column;
                                text-align: left;
                                .itemTitle {
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 22px;
                                    width: 400px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                                .itemContent {
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 17px;
                                    color: #b5b6c7;
                                    opacity: 1;
                                }
                                .tags{
                                    font-size: 12px;
                                    color: #1890ff;
                                    border: 0px solid #1890ff;
                                    height: 20px;
                                    width: 30px;
                                    display: inline-block;
                                    padding-left: 6px;
                                    border-radius: 5px;
                                    font-family: cursive;
                                    line-height: 21px;
                                }
                            }
                        }
                        .itemBottom2 {
                            // float: right;
                            text-align: right;
                            flex-direction: column;
                            .time {
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 22px;
                                color: #3f4254;
                                opacity: 1;
                            }
                            .endTime {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17px;
                                color: #b5b6c7;
                                opacity: 1;
                            }
                        }
                        .itemBottom3 {
                            // flex: 1;
                            .role {
                                font-size: 12px;
                                //   font-family: .PingFang SC;
                                font-weight: 400;
                                line-height: 17px;
                                color: #3f4254;
                                opacity: 1;
                            }
                        }
                        .itemBottom4 {
                            .digital {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                .barNumber {
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 17px;
                                    color: #b5b6c7;
                                    opacity: 1;
                                }
                                .barProgress {
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 17px;
                                    color: #b5b6c7;
                                    opacity: 1;
                                }
                            }
                            .progress {
                                width: 278px;
                                opacity: 1;
                                border-radius: 3px;
                            }
                        }
                        .itemBottom5 {
                            display: flex;
                            justify-content: space-between;
                            // text-align: center;
                            .editor {
                                padding-right: 10px;
                                width: 30px;
                                height: 30px;
                                background-color: #f5f5f5;
                                padding: 2px;
                                border-radius: 5px;
                                .iconfont {
                                    font-size: 20px;
                                }
                                .smalldash{
                                    line-height: 30px;
                                }
                                .edit-item{
                                    font-size:12px;
                                }
                            }
                            
                            .delete {
                                padding-right: 15px;
                                .iconfont {
                                    font-size: 20px;
                                }
                            }
                            .delete:hover {
                                color: #3699ff;
                            }
                        }
                    }
                    .itemBottom:hover{
                        background-color: #f5f5f554;
                    }
                    .more{
                        text-align: center;
                        margin-top: 30px;
                        .more-a{
                            background-color: #f2f2f2;
                            border: 0;
                            color: #aaa;
                        }
                        .more-a:hover{
                            background-color: #cecbcb;
                            color: #4e4c4c;
                        }
                    }
                    
                }
            }
        }
    }
    .OKRDetails {
        opacity: 1;
        background-color: #fff;
        .details {
            padding: 18px 23px;
            border-bottom: 1px solid #ccc;
            .title {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #3f4254;
                opacity: 1;
            }
        }
        .content {
            input:focus {
                outline: none;
            }
            .int {
                border: none;
                width: 100%;
                padding: 15px;
                background: #ffffff;
                border: 1px solid #3370ff;
                opacity: 1;
                border-radius: 5px;
            }
            .progressThan {
                margin-top: 15px;
                display: flex;
                justify-content: flex-start;
                .progress {
                    padding-left: 25px;
                    padding-right: 35px;
                }
                .totalScore {
                    padding-left: 35px;
                }
            }
            .founder {
                margin-top: 26px;
                .information {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #b5b6c7;
                    opacity: 1;
                }
                .theName {
                    margin-top: 9px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #3f4254;
                    opacity: 1;
                }
            }
            .starStop {
                margin-top: 16px;
                .lintTime {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 17px;
                    color: #b5b6c7;
                    opacity: 1;
                }
                .lintDate {
                    margin-top: 9px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #3f4254;
                    opacity: 1;
                }
            }
            .projectPersonnel {
                margin-top: 27px;
                .responsiblePerson {
                    display: flex;
                    flex-direction: column;
                    .nickName {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        color: #b5b6c7;
                        opacity: 1;
                    }
                    .headPortrait {
                        width: 95%;
                        position: relative;
                        margin-top: 9px;
                        display: flex;
                        align-items: center;
                        word-wrap: break-word;
                        word-break: break-all;
                        flex-wrap: wrap;
                        .itemPortrait {
                            position: relative;
                            margin-right: 15px;
                            margin-bottom: 15px;
                            .del-icon {
                                position: absolute;
                                top: -8px;
                                right: 0;
                                width: 10px;
                                height: 10px;
                                color: #ff0000;
                            }
                            .person {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                opacity: 0;
                                z-index: 100;
                            }
                            .img {
                                width: 36px;
                                height: 36px;
                                background: rgba(0, 0, 0, 0);
                                border-radius: 50%;
                                opacity: 1;
                                border: 1px solid #ccc;
                                margin-bottom: 6px;
                            }
                            div {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17px;
                                color: #3f4254;
                                opacity: 1;
                                text-align: center;
                            }
                        }
                        .icon {
                            position: relative;
                            .iconfont {
                                font-size: 36px;
                                color: #c1c5cc;
                            }
                        }
                    }
                }
                .participants {
                    margin-top: 13px;
                    .secondary {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        color: #b5b6c7;
                        opacity: 1;
                    }
                    .headPortrait {
                        position: relative;
                        margin-top: 9px;
                        .itemPortrait {
                            .img {
                                width: 36px;
                                height: 36px;
                                background: rgba(0, 0, 0, 0);
                                border-radius: 50%;
                                opacity: 1;
                                border: 1px solid #ccc;
                                margin-bottom: 6px;
                            }
                            div {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 17px;
                                color: #3f4254;
                                opacity: 1;
                            }
                        }
                        .icon {
                            position: absolute;
                            left: 35px;
                            top: -8px;
                            .iconfont {
                                padding-left: 18px;
                                font-size: 36px;
                                color: #c1c5cc;
                            }
                        }
                    }
                }
            }
        }
        .btn {
            padding: 0 15px;
            padding-top: 80px;
            float: right;
            .cancel {
                padding: 6px 18px;
                margin-right: 15px;
                outline: none;
                border: none;
            }
            .cancel:hover {
                background-color: #2994ff;
                color: #fff;
            }
            .confirm {
                padding: 6px 18px;
                outline: none;
                border: none;
            }
            .confirm:hover {
                background-color: #2994ff;
                color: #fff;
            }
        }
        .comments {
            float: left;
            padding: 0 15px;
            .title {
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                color: #3f4254;
                opacity: 1;
            }
        }
    }
    .alert-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button {
            width: 120px;
        }
    }
    .center-list {
        .list-item {
            position: relative;
            margin: 8px 0;
            width: 300px;
            padding: 12px;
            border: 1px solid #e8ebed;
            border-radius: 6px;
            .item-avatar {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                margin-right: 8px;
            }
            .item-txt {
                margin: 8px 0;
                font-size: 15px;
                color: #3f4254;
            }
            .item-name {
                font-size: 14px;
                color: #666;
            }
            .tab-del {
                display: none;
                position: absolute;
                top: 7px;
                right: 10px;
                color: #666;
                font-size: 18px;
            }
            .schedule {
                position: absolute;
                bottom: 1px;
                left: 0;
                width: 50%;
                height: 2px;
                background: #f5222d;
            }
        }
        .list-item:hover {
            .tab-del {
                display: inline-block;
            }
        }
    }
    .icon-radio {
        position: absolute;
        top: 5px;
        right: 15px;
        width: 10px;
        height: 10px;
    }
    .relevanceContent {
        padding-bottom: 50px;
    }
    .model-comment{
        padding-bottom: 50px;
        background-color: #F5F6F7;
        box-sizing: border-box;
        .model-comment-content{
            padding: 10px 20px;
            .add-comment-model{
                width: 100%;
                padding: 10px;
                margin: 10px auto;
                background-color: #fff;
                border-radius: 6px;
                textarea{
                    resize: none;
                    width: 100%;
                    height: 80px;
                    border: none;
                    font-size: 13px;
                    color: #666;
                    outline: none;
                }
            }
            .list-item{
                margin-bottom: 15px;
                .comment-avatar{
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 5px;
                    vertical-align: middle;
                }
                .comment-name{
                    font-size: 14px;
                    color: #666;
                }
                .comment-time{
                    margin-left: 5px;
                    font-size: 12px;
                    color: #999;
                }
                .comment-text{
                    padding-left: 35px;
                    margin-top: 5px;
                    font-size: 14px;
                    color: #666;
                }
            }
        }
        .model-comment-title{
            font-size: 17px;
            color: #666;
            padding: 10px 20px;
            border-bottom: 1px solid #E5E6E9;
        }
        .more > a:hover{
            background-color:red;
        }
    }
</style>
